<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="450">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('create')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            Create New User
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                        <v-text-field
                        dense
                        outlined
                        v-model="form[3].name"
                        :rules="form[3].rule"
                        label="Name"
                        required
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                        <v-text-field
                        dense
                        outlined
                        v-model="form[0].email"
                        :rules="form[0].rule"
                        label="Email"
                        required
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[2].selected"
                        :items="form[2].type"
                        :rules="form[2].rule"
                        item-text="name"
                        item-value="ID"
                        label="Select Type"
                        outlined
                        hide-details="auto"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                    <span class="text-subtitle-2">Choose Role</span>
                </v-col>
                <v-col cols="12" md="6">
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="form[1].header"
                        :items="form[1].items"
                        v-model="form[1].selected"
                        show-select
                        item-key="ID"
                        hide-default-footer
                        :loading="tableloading"
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template v-slot:item.CountryName="{ item }">
                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                        <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(item, 'update')">{{item.CountryName}}</a>
                        </template>
                        <span>Update Country</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip top>
                        <template v-slot:activator="{ on }">
                        <span>
                            <a v-on="on" id="custom-hover" @click.prevent="viewMore(item, 'delete')"><v-icon color="red" right x-small>fa fa-trash</v-icon></a>
                        </span>
                        </template>
                        <span>Remove Country</span>
                        </v-tooltip>
                    </template>
                    </v-data-table>
                    <v-input
                        error
                        readonly
                        v-model="form[1].selected"
                        :rules="form[1].rule"
                        height="0"
                        class="hide-input-text"
                    >
                    </v-input>
                </v-col>
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
export default {
    props: ['dialog', 'modalInfo', 'customText', 'system', 'master', 'allInfo'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {email: '', rule: [] },
                {header: [{text: 'ID', value: 'ID'}, {text: 'Role Name', value: "RoleName"}], items: [], rule: [], selected: [] },
                {type: [], rule: [], selected: '' },
                {name: '', rule: [] },
            ],
            search: '',

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },
            tableloading: false
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
                this.getAllType();
                this.getAllRole();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },
    },

    created(){
        
    },

    methods: {
        async getAllRole(){
            let list = [];
            this.tableloading = true;
            this.form[1].items = [];

            try{
                let response = await this.$store.dispatch("getAllRole", {search: ''});
                if (response.data.code == "AP000"){
                    let dt = response.data.record;
                    this.form[1].items = dt;
                    list = dt;
                }

                this.tableloading = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getAllType(){
            let list = [];
            try{
                let response = await this.$store.dispatch("getAllType", {});
                let dt = response.data.record;
                this.form[2].type.push({name: "Select Type", ID: ""});
                if (response.data.code == "AP000"){
                    for(var i=0; i<dt.length; i++){
                        dt[i].name = `${dt[i].ID} - ${dt[i].TypeName}`;
                        this.form[2].type.push(dt[i]);
                    }
                }

                list = dt;
                //this.form[1].roles = dt;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async checkRef(val){
            if (val != undefined){
                let system = await this.allInfo;
                let check = system.some((item) => {
                    return item.email.toLowerCase() == val.toLowerCase();
                });

                return check;
            }

            else{
                return '';
            }
        },

        onRuleChange(after, before) {
            this.form[0].rule = [];
            this.form[1].rule = [];
            this.form[2].rule = [];
        },

        async beforeCreateForm(flag){
            //let check = await this.checkRef(this.form[0].email);
            
            this.form[0].rule = [
                v => !!v || 'Email is required',
                v => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(v) || 'Please enter a valid email address',
                //v => !v || !check || 'This email is already registered. Please enter another'
            ];

            this.form[1].rule = [
                v => !v || this.form[1].selected.length != 0 || 'Role is required',
            ];

            this.form[2].rule = [
                v => !!v || 'Type is required',
            ];

            this.form[3].rule = [
                v => !!v || 'Name is required',
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.$emit('closed', false);
            }
        },

        handleValue(){
            this.onRuleChange();
            this.form[0].email = '';
            this.form[1].items = [];
            this.form[1].selected = [];
            this.form[2].selected = '';
            this.form[3].name = '';
            this.search = '';
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'create'){
                    this.form.flag = 'create';
                    this.$emit('confirm', this.form);
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },
    }
}
</script>