<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Internal Users</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-col cols="12" md="6"><v-btn v-if="validateArr([validateAccess('user_add')])" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create User
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
                <v-btn-toggle class="col-12 col-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items.sync="items"
                        hide-default-footer
                        :loading="tableloading"
                        :search="search"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.ID}}</td>
                            <td>{{props.item.adminID}}</td>
                            <td>{{props.item.userID}}</td>
                            <td>
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <a v-on="on" @click="viewProfile(props.item)" style="text-decoration: underline" id="custom-hover">{{props.item.name}}</a>
                                    </template>
                                    <span v-if="validateAccess('user_updateprofile')">Update User Profile</span>
                                    <span v-else>View User Profile</span>
                                </v-tooltip>
                            </td>
                            <td>{{props.item.email}}</td>
                            <td>{{props.item.ProfileID}}</td>
                            <td>{{props.item.Type_Name}}</td>
                            <td>{{props.item.Role_Name}}</td>
                            <td>{{props.item.createDate}}</td>
                            <td>{{props.item.lastLoginDate}}</td>
                            <td>
                                <v-chip
                                x-small
                                class="ma-2"
                                :color="statusCheck(props.item.ActiveStatus, 'color')"
                                text-color="white">
                                    {{statusCheck(props.item.ActiveStatus, 'text')}}
                                </v-chip>
                            </td>
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
        <alertdelete :dialog="ma.modal_delete" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :item="ma.item" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></alertdelete>
        <addprofile :dialog="ma.modal_profile" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></addprofile>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateUser';
import alert from '.././modals/AlertStatus';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import alertdelete from '../modals/AlertDeleteProfile';
import addprofile from '.././modals/CreateProfileExistingUser';
import {rule} from '../../assets/js/checkPerm';
import * as moment from "moment";
import * as tz from "moment-timezone";

export default {
    name: 'Internal Users',
    title: 'Internal Users',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Internal Users',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '3%' },
                { text: 'ID', value: 'ID', width: '3%' },
                { text: 'Admin ID', value: 'adminID', width: '3%' },
                { text: 'User ID', value: 'userID', width: '3%' },
                { text: 'Name', value: 'name', width: '10%' },
                { text: 'Email', value: 'email', width: '10%' },
                { text: 'Profile ID', value: 'ProfileID', width: '15%' },
               // { text: 'Type Name', value: 'typeName', width: '15%' },
               // { text: 'Role Name', value: 'roleName', width: '15%' },
               // { text: 'Type ID', value: 'type', width: '15%' },
                { text: 'Profile Type', value: 'Type_Name', width: '15%' },
                { text: 'Role', value: 'Role_Name', width: '15%' },
                { text: 'Created Date', value: 'createDate', width: '15%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Last Login Date', value: 'lastLoginDate', width: '15%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                { text: 'Status', value: 'ActiveStatus', width: '15%' },
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_delete: false,
                modal_profile: false,
                readonly: true,
                allInfo: [],
                item: {}
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },

            services: []
        }
    },

    components: {create, alert, alertbox2, alertdelete, addprofile},

    async created(){
        this.getUpdatedProfile();
        this.getUsers();
    },

    methods: {
        async getUsers(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("getUsers", {});
                let response2 = await this.$store.dispatch("getAdminsTypeRole", {});

                this.services = response;
                await timer.sleep(100);
                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let dt2 = response2.data.record;
                let recordCount = response.data.count;


                for(var i=0; i<dt.length; i++){
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].createDate = moment(new Date(dt[i].createDate)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].updateDate = dt[i].updateDate == null ? "N/A" : moment(new Date(dt[i].updateDate)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].lastLoginDate = dt[i].lastLoginDate == null ? "N/A" : moment(new Date(dt[i].lastLoginDate)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].name = dt[i].name == null ? "N/A" : dt[i].name;

                    let profileid = dt2.find(item => item.userID == dt[i].userID);
                    dt[i].profileid = profileid == undefined ? "N/A" : profileid.ID;
                    dt[i].ProfileID = dt[i].ProfileID == undefined ? "N/A" : dt[i].ProfileID;
                }

                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        getUpdatedProfile(){
            if (this.$route.query.action == "profileremoval"){
                this.swal.notification = true;
                this.swal.scolor = 'green';
                this.swal.message = `${ERR.HANDLE('AP000')}: Profile Deleted!`;
                this.$router.replace({'query': null});
            }

            if (this.$route.query.action == "createProfile"){
                this.swal.notification = true;
                this.swal.scolor = 'green';
                this.swal.message = `${ERR.HANDLE('AP000')}: Profile Updated!`;
                this.$router.replace({'query': null});
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addUser(v){
            this.btn_loading = true;
            this.tableloading = true;
            console.log('toadd', v);

            try{
                this.swal.scolor = 'green';
                let req = {name: v[3].name, email: v[0].email};
                let response = await this.$store.dispatch("userRegister", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    let response2 = await this.$store.dispatch("userReset", req);
                    if (response2.data.code === 'AP000'){
                        await this.getUsers();
                        this.add(v, response.data.createduserid);
                        this.ex.modal = true;
                        this.ex.content.headline = 'Email Registered!';
                        this.ex.content.icon = 'fa fa-check';
                        this.ex.content.chipColor = '#a0e8af';
                        this.ex.content.iconColor = 'green';
                        this.ex.content.maintext = 'Please inform user of this email to check their inbox/junk mail for verification link.';
                        this.ex.content.smalltext = 'If verification link is expired, please reset the same email again.';

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Email Registered!`;
                    }
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This email is already registered`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }

            /*
            try{
                this.swal.scolor = 'green';
                let req = {email: v[0].email};
                let response = await this.$store.dispatch("userRegister", req);
                await timer.sleep(800);
                if (response.data.code === 'AP000'){
                    let response2 = await this.$store.dispatch("userReset", req);
                    if (response2.data.code === 'AP000'){
                        await this.getUsers();
                        this.ex.modal = true;
                        this.ex.content.headline = 'Email Registered!';
                        this.ex.content.icon = 'fa fa-check';
                        this.ex.content.chipColor = '#a0e8af';
                        this.ex.content.iconColor = 'green';
                        this.ex.content.maintext = 'Please inform user of this email to check their inbox/junk mail for verification link.';
                        this.ex.content.smalltext = 'If verification link is expired, please reset the same email again.';

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Email Registered!`;
                    }
                }
                else if (response.data.code === 'RD002'){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}: This email is already registered`;
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }

                this.tableloading = false;
                this.btn_loading = false;
            }

            catch(err){
                this.swal.notification = true;
                this.swal.scolor = 'red';
                this.swal.message = ERR.HANDLE(response.data.code);
            }
            */
        },

        async add(v, id){
            this.btn_loading = true;
            this.tableloading = true;

            let loop = v[1].selected.length;

            for(let i=0; i<loop; i++){
                try{
                    this.swal.scolor = 'green';
                    let req = {userid: id, type: v[2].selected, role: v[1].selected[i].ID};
                    console.log('uce', req);
                    let response = await this.$store.dispatch("createProfile", req);
                    await timer.sleep(800);
                    if (response.data.code === 'AP000'){
                            await this.getUsers();
                            this.swal.notification = true;
                            this.swal.scolor = 'green';
                            this.swal.message = `${ERR.HANDLE(response.data.code)}: Profile Created!`;
                    }

                    else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: This profile already exists`;
                    }

                    else{
                        this.tableloading = false;
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                    }

                    this.tableloading = false;
                    this.btn_loading = false;
                }

                catch(err){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = ERR.HANDLE(response.data.code);
                }
            }
        },

        async userReset(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("userReset", {email: this.ma.modalInfo.email})
                if (response.data.code === 'AP000'){
                        await this.getUsers();
                        this.ex.modal = true;
                        this.ex.content.headline = 'Email Reset!';
                        this.ex.content.icon = 'fa fa-check';
                        this.ex.content.chipColor = '#a0e8af';
                        this.ex.content.iconColor = 'green';
                        this.ex.content.maintext = 'Please inform user of this email to check their inbox/junk mail for verification link.';
                        this.ex.content.smalltext = 'If verification link is expired, please reset the same email again.';

                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Email has been reset!`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async deleteProfile(){
            try{
                this.tableloading = true;
                let req = {userid: this.ma.modalInfo.userID, type: this.ma.item.typeID, role: this.ma.item.roleID};
                let response = await this.$store.dispatch("deleteProfile", req)
                if (response.data.code === 'AP000'){
                        await this.getUsers();
                        this.swal.notification = true;
                        this.swal.scolor = 'green';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: Profile has been deleted!`;
                }

                else{
                    this.tableloading = false;
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                }
            }

            catch(err){
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.tableloading = false;
            }
        },

        async addProfileExistingUser(id, v){
            this.btn_loading = true;
            this.tableloading = true;

            let loop = v[1].selected.length;

            for(let i=0; i<loop; i++){
                try{
                    //this.swal.scolor = 'green';
                    let req = {userid: id, type: v[2].selected, role: v[1].selected[i].ID};
                    console.log('uce', req);
                    let response = await this.$store.dispatch("createProfile", req);
                    await timer.sleep(800);
                    if (response.data.code === 'AP000'){
                            await this.getUsers();
                            this.swal.notification = true;
                            this.swal.scolor = 'green';
                            this.swal.message = `${ERR.HANDLE(response.data.code)}: Profile Created!`;
                    }

                    else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}: This profile already exists`;
                    }

                    else{
                        this.tableloading = false;
                        this.swal.notification = true;
                        this.swal.scolor = 'red';
                        this.swal.message = `${ERR.HANDLE(response.data.code)}`;
                    }

                    this.tableloading = false;
                    this.btn_loading = false;
                }

                catch(err){
                    this.swal.notification = true;
                    this.swal.scolor = 'red';
                    this.swal.message = ERR.HANDLE(response.data.code);
                }
            }
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'reset'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
            }

            if (flag == 'create'){
                this.ma.modal = true;
                this.ma.allInfo = this.items;
            }

            if (flag == 'add'){
                this.ma.modal_profile = true;
                this.ma.modalInfo = row;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        viewDelete(row, it, flag){
            if (flag == 'delete'){
                this.ma.modal_delete = true;
                this.ma.modalInfo = row;
                this.ma.item = it;
            }

            this.ma.modal_action_type = flag;
        },

        viewProfile(row){
            this.ma.modalInfo = row;
            this.$router.push({ name: "User Profile Details", params: {id: row.ID}, query: {row: JSON.stringify(row)}});
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ma.modal_delete = value;
                this.ma.modal_profile = value;
                this.ex.modal = value;
            }
        },

        onConfirm(value){
            console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;

                let addneworexisting = this.items.find(i => i.email == value[0].email);
                console.log('addneworexisting', addneworexisting);

                if (addneworexisting == undefined)
                    this.addUser(value);

                else{
                    this.addProfileExistingUser(addneworexisting.userID, value);
                }
            }

            if (value.flag === 'add'){
                this.ma.modal_profile = false;
                this.addProfileExistingUser(value);
            }

            if (value === true){
                if (this.ma.modal_action_type == 'reset')
                {
                    this.ma.modal_status = false;
                    this.userReset();
                }

                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_delete = false;
                    this.deleteProfile();
                }
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getUsers();
          }, 800);
        },

        validateArr(arr){
            let truth = arr.some(item => {
                return item === true;
            });

            return truth;
        },

        statusCheck(val, typ){
            if (typ == 'text'){
                    if (val === 0){ return 'Disable'}
                    else if (val === 1){return 'Enable'}
                    else {return 'null'}
            }

            if (typ == 'color'){
                    if (val === 0){ return 'red'}
                    else if (val === 1){return 'green'}
                    else {return 'grey'}
            }
        },
    }
}
</script>